var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cfde944e804fa70d181285e94483e62637074421"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN =
  process?.env?.SENTRY_DSN || process?.env?.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://1bcdebaa8afe4974899604af25012a64@o4504851258736640.ingest.sentry.io/4504851395837952",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  ignoreErrors: [
    "Segment library not loaded",
    "Network Error: Failed to fetch",
    "Network Error",
    "Error fetching client ids",
    "Error: Segment library not loaded",
    "Request failed with status code 400",
    "Error sending campaign data",
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

import("@sentry/nextjs").then((lazyLoadedSentry) => {
  Sentry.addIntegration(
    lazyLoadedSentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      mask: ["password", "credit-card", "social-security-number"],
      networkCaptureBodies: true,
      networkCaptureHeaders: true,
      networkDetailAllowUrls: [
        window.location.origin,
        "https://alter-prod.hasura.app/v1/graphql",
        "https://altermestore.myshopify.com/api/2023-10/graphql.json",
        "https://altermestore.myshopify.com/api",
      ],
    })
  );
});
